import React from 'react';

import { BorderRadius } from '../../utils/variables';

import loadComponent from '../Loadable';

const H2 = loadComponent('h2');

export default function CheckoutInformation({ initial, orderId }) {
  return (
    <div style={{ marginBottom: `30px` }}>
      <H2>Applicant Information</H2>
      <div className="accountOrders">
        <div className="header" style={headerStyle}>
          <div className="item" style={colStyle}>
            <strong style={labelStyle}>Reference</strong> #{orderId}
          </div>
          <div className="item" style={colStyle}>
            <strong style={labelStyle}>Name</strong> {initial?.first_name}
          </div>
          <div className="item" style={colStyle}>
            <strong style={labelStyle}>Surname</strong> {initial?.last_name}
          </div>
          <div className="item end" style={endCol}>
            <strong style={labelStyle}>Status</strong>
            <span style={{ color: `#FFBF00` }}>Unpaid</span>
          </div>
        </div>
      </div>
    </div>
  );
}

const headerStyle = {
  backgroundColor: `#f9f9f9`,
  border: `1px solid rgba(0,0,0,0.2)`,
  borderRadius: BorderRadius,
  padding: `1.125rem 1.875rem`,
  display: `flex`,
  flexWrap: `wrap`,
  alignItems: `center`,
  fontSize: `0.875rem`,
};

const colStyle = {
  marginRight: `45px`,
};

const labelStyle = {
  display: `block`,
  textTransform: `uppercase`,
};

const endCol = { marginLeft: `auto`, textAlign: `right` };
